<template>
  <div class="my-1 px-3">
    <v-row v-for="week in nWeek" :key="week" class="ma-0">
      <v-col v-for="day in 7" :key="day" class="pa-2px week-day-col">
        <div v-if="getMonthDay(week, day) > 0">
          <div v-if="getType(getMonthDay(week, day)) === cellTypes.vacant">
            <Vacant
              :day="getMonthDay(week, day)"
              :weekDay="day"
              :point="getPoint(getMonthDay(week, day))"
            />
          </div>
          <div v-else-if="getType(getMonthDay(week, day)) === cellTypes.unavailable">
            <Unavailable
              :day="getMonthDay(week, day)"
              :weekDay="day"
            />
          </div>
          <div v-else>
            <Few
              :day="getMonthDay(week, day)"
              :weekDay="day"
              :point="getPoint(getMonthDay(week, day))"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vacant from '@/components/ReservationCalendar/MonthDays/DayItem/Vacant'
import Unavailable from '@/components/ReservationCalendar/MonthDays/DayItem/Unavailable'
import Few from '@/components/ReservationCalendar/MonthDays/DayItem/Few'
import { CalendarCellType } from '@/constants/reservation-calendar'
import { createIsoDate, getJstToday } from '@/utils/get-date'
import { BookCalendarFewFrom } from '@/constants'
import searchVacancyMixin from '@/views/VacancySearch/Reservation/searchVacancyMixin'

export default {
  name: 'MonthDays',
  mixins: [searchVacancyMixin],
  components: {
    Vacant,
    Unavailable,
    Few
  },
  data () {
    return {
      cellTypes: CalendarCellType
    }
  },
  methods: {
    getMonthDay (week, day) {
      const monthDay = (week - 1) * 7 + day - this.startDay

      if (monthDay >= this.days) {
        return -1
      }

      return monthDay
    },
    getFullDate (day) {
      return createIsoDate(this.year, this.month, day)
    },
    getPriceAndInv (day) {
      return this.$store.getters.getPriceAndInventory(this.getFullDate(day))
    },
    getType (day) {
      // introduced in TO2020-1064: must check the date is in the allowed check-in range
      // no need to check the checkOutDate because the max checkInDate
      // is the day before the canCheckOutUntil.
      const date = this.getFullDate(day)
      if (date < this.canCheckInFrom || date > this.canCheckInUntil) return CalendarCellType.unavailable
      // also if the date is today or before, it's unavailable
      if (date <= getJstToday()) return CalendarCellType.unavailable

      const inventoryData = this.getPriceAndInv(day)
      if (!inventoryData) return CalendarCellType.unavailable
      if (inventoryData.roomsAvailable === 0) return CalendarCellType.unavailable
      if (inventoryData.roomsAvailable <= BookCalendarFewFrom) return CalendarCellType.few

      return CalendarCellType.vacant
    },
    getPoint (day) {
      return this.getPriceAndInv(day).contractPrice
    }
  },
  computed: {
    year () {
      return this.$store.state.reservationCalendar.year
    },
    month () {
      return this.$store.state.reservationCalendar.month
    },
    days () {
      return new Date(this.year, this.month, 0).getDate() + 1
    },
    startDay () {
      return (new Date(this.year, this.month - 1, 1).getDay() + 7) % 7
    },
    nDay () {
      return (this.startDay + this.days)
    },
    nWeek () {
      return Math.floor((this.nDay + 5) / 7)
    }
  }
}
</script>
