<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/vacancy-search/reservation">
        {{ $t("menu.main.vacancySearch") }}
      </router-link>
      >
      <template v-if="facilityInfo">
        {{ facilityInfo.name }}
      </template>
    </Breadcrumbs>
    <div class="form-container" v-if="facilityInfo">
      <v-img
        :lazy-src="lazyReservationImgUrl"
        :src="getImage(facilityInfo.mainImageUrl, true)"
        max-height="250"
      />
      <div class="bg-white text-center pt-5">
        <div class="text-default --w-regular">
          <FacilityLocation :address="facilityInfo.address1" />
        </div>
        <h1 class="text-large --prm-light">{{ facilityInfo.name }}</h1>
        <v-btn
          elevation="0"
          :href="facilityInfo.facilityHpUrl"
          target="_blank"
          class="my-4 text-medium --w-bold --prm-light"
          rounded
          x-large
        >
          {{ $t('buttons.openFacilityInformation') }}
          <IconOpen />
        </v-btn>
        <div class="my-4 text-medium --c-x-dark --w-bold">
          {{ $t('text.roomType.pleaseSelect') }}
        </div>
        <div class="px-3">
          <RoomTypeSelect :roomTypes="roomTypes" v-model="roomTypeId" />
        </div>
        <Calendar />
        <ReservationForm
          @commit="goToPlanSelect"
          :selectedRoomTypeId="roomTypeId"
          @changeRoomTypeId="v => roomTypeId = v"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import IconOpen from '@/components/icons/IconOpen'
import RoomTypeSelect from '@/components/RoomTypeSelect'
import Calendar from '@/components/ReservationCalendar'
import ReservationForm from '@/components/ReservationForm'
import mixin from '../searchVacancyMixin'
import FacilityLocation from '@/components/Facility/FacilityLocation'
import { getDateString } from '@/utils/get-date'
import { lazyImageMixin } from '@/mixins/lazy-image.mixin'
import { getHashcodeOfObjects } from '@/utils/string'

export default {
  name: 'Reservation',
  mixins: [mixin, lazyImageMixin],
  components: {
    Breadcrumbs,
    IconOpen,
    RoomTypeSelect,
    Calendar,
    ReservationForm,
    FacilityLocation
  },
  watch: {
    async shouldLoadData (_) {
      await this.loadData()
    }
  },
  created () {
    if (this.checkInDate) {
      const split = this.checkInDate.split('-')
      this.$store.commit('setReservationDate', {
        year: parseInt(split[0]),
        month: parseInt(split[1])
      })
    }
    this.$store.commit('setBookingPlanId', null)
  },
  async mounted () {
    await this.loadData()
  },
  data () {
    return {
      isVisibleComplete: false,
      isVisibleCancel: false,
      isVisibleReservationCancellationComplete: false
    }
  },
  methods: {
    async loadData () {
      if (this.resFromDate && this.resToDate) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            const facilityInfo = await this.$store.dispatch('getPricingOfFacility', {
              facilityId: this.facilityId,
              fromDate: this.resFromDate,
              toDate: this.resToDate
            })
            this.$store.commit('setFacilityInfoForBooking', facilityInfo)
            this.$store.commit('setRoomTypePrices', facilityInfo.roomTypes)
            if (this.roomTypeId && facilityInfo.roomTypes.some(rt => rt.id === this.roomTypeId)) {
              // check if the room type exists in the new search result, and if exists keep that
            } else {
              this.$store.commit('setRoomTypeId', facilityInfo.roomTypes[0].id)
            }
          })
        })
      }
    },
    async goToPlanSelect () {
      if (this.$store.getters.isReadyForPriceTemporaryBooking) {
        await this.$router.push({ name: 'home-vacancy-search-reservation-select-plan' })
      }
    }
  },
  computed: {
    facilityInfo () {
      return this.$store.state.newBooking.facilityInfo
    },
    facilityId () {
      return parseInt(this.$route.params.id)
    },
    year () {
      return this.$store.state.reservationCalendar.year
    },
    month () {
      return this.$store.state.reservationCalendar.month
    },
    resFromDate () {
      return this.$store.state.reservationCalendar.month
        ? getDateString(this.$store.state.reservationCalendar.year, this.$store.state.reservationCalendar.month, 1)
        : null
    },
    resToDate () {
      const month = this.$store.state.reservationCalendar.month
      const year = this.$store.state.reservationCalendar.year
      const isLastMonth = month === 12
      return this.$store.state.reservationCalendar.month
        ? getDateString(isLastMonth ? year + 1 : year, isLastMonth ? 1 : month + 1, 1)
        : null
    },
    shouldLoadData () {
      return getHashcodeOfObjects(this.facilityId, this.$store.state.reservationCalendar.month, this.$store.state.reservationCalendar.year)
    },
    roomTypes () {
      return this.$store.state.reservationCalendar.roomTypes
    },
    roomTypeId: {
      get () {
        return this.$store.state.reservationCalendar.roomTypeId
      },
      set (val) {
        this.$store.commit('setRoomTypeId', val)
      }
    }
  }
}
</script>
